import { Api } from "@api/ApiTransport";
import axios from "axios";
import { create } from "zustand";
import { useContractsStore } from "./storeContratti";
import { useStoreLoader } from "./storeLoader";
import { useSessionStore } from "./storeSession";

const api = Api.getInstance();

// Define the types
export type Document = {
  id: number;
  createdAt: string;
  updatedAt: string | null;
  name?: string;
  url: string;
  type: number;
  format: string;
  idSession: number;
  formato?: string;
  base64?: string;
};

export type DocumentData = Document;

export type User = {
  id: number;
  name: string;
  surname: string;
  phone: string;
  email: string;
  invitedBy: string;
  createdAt: string;
  updatedAt: string | null;
  token: string | null;
  tokenBrokenArrow: string | null;
  documents?: Document[];
  flowSorgente?: string;
  paramsUtm?: string;
  onboardingEmail?: string;
  onboardingPhone?: string;
};

export type authState = {
  user: User | null;
  justSigned: boolean;
  setJustSigned: (justSigned: boolean) => void;
  userSign: (payload: any, callback: (data: any) => void) => Promise<void>;
  userLog: (
    payload: any,
    callback: () => void,
    callbackCalcolaFree?: () => void
  ) => Promise<void>;
  fetchUser: (
    callback?: () => void,
    callbackError?: (error: string) => void
  ) => Promise<void>;
  setUser: (user: User | null) => void;
  userLogOut: (callback?: () => void) => Promise<void>;
  deleteAllDocuments: () => Promise<void>;
  deleteDocumentFront: () => Promise<void>;
  deleteDocumentBack: () => Promise<void>;
  deleteDocumentPdf: () => Promise<void>;
  uploadID: (payload: any, callback: (data: any) => void) => Promise<void>;
  deleteUser: () => Promise<void>;
  editUser: (payload: any) => Promise<void>;
  forgotPassword: (payload: any, callback: () => void) => Promise<void>;
  resetPassword: (payload: any, callback: () => void) => Promise<void>;
  updateDocumentDataManually: (sessionId: number, data: any) => Promise<void>;
  updateSessionDataManually: (
    sessionId: number,
    data: any,
    callback: () => void
  ) => Promise<void>;
  updateSessionDataManuallyBusinessInfo: (
    sessionId: number,
    data: any,
    callback: () => void
  ) => Promise<void>;
  updateSessionSetIban: (
    sessionId: number,
    data: any,
    callback: () => void
  ) => Promise<void>;
  editUserOnboarding: (data: any, callback: () => void) => Promise<void>;
};

export const useAuthStore = create<authState>((set, get) => ({
  user: null,
  justSigned: false,
  setJustSigned: (justSigned: boolean) => set({ justSigned }),
  userSign: async (payload, callback) => {
    const { data } = await api.post("/signup", payload);
    if (data?.user.token) {
      api.setAuthorizationHeader(data.user.token);
      if (payload.adminID) {
        useSessionStore
          .getState()
          .saveIdAndToken({ idSession: "", token: data.user.token });
      } else {
        localStorage.setItem("token", data.user.token);
      }
    }
    set({ user: data.user, justSigned: true });
    const setSession = useSessionStore.getState().setSession;
    setSession(data.session);

    //questa function mi occorre per prendere da onbording sensa registrazione che ha calcolaot il risparmio, ha creato una nuova utenza e si registra MA gli devo ereditare al sessione
    const isOnboardingNoSign = localStorage.getItem(
      "isOnboarding_without_sign"
    );
    const onBoardingNoSignidSession = localStorage.getItem(
      "onBoardingNoSignidSession"
    );
    const onBoardingNoSignToken = localStorage.getItem("onBoardingNoSignToken");
    if (data?.user.token && isOnboardingNoSign && onBoardingNoSignidSession) {
      const respEreditaSession = await api.post("/sessions/accorpare", {
        idSession: onBoardingNoSignidSession,
        fakeToken: onBoardingNoSignToken,
      });
      const respNewSession = await useSessionStore.getState().getSession(); //riprendo la nuova sessione ereditata
      // console.log(respNewSession)
    }
    localStorage.removeItem("isOnboarding_without_sign");
    localStorage.removeItem("onBoardingNoSignidSession");
    localStorage.removeItem("onBoardingNoSignToken");

    callback({ fromOnbordingNoSign: isOnboardingNoSign }); //nel caso in cui ho fatto onboarding senza registrazione e mi sono registrato
  },
  userLog: async (payload, callback, callbackCalcolaFree) => {
    const { data } = await api.post("/login", payload);
    if (data?.user.token) {
      api.setAuthorizationHeader(data.user.token);
      localStorage.setItem("token", data.user.token);
    }
    set({ user: data.user, justSigned: true });

    //questa function mi occorre per prendere da onbording sensa registrazione che ha calcolaot il risparmio, ha creato una nuova utenza e si registra MA gli devo ereditare al sessione
    const isOnboardingNoSign = localStorage.getItem(
      "isOnboarding_without_sign"
    );
    const onBoardingNoSignidSession = localStorage.getItem(
      "onBoardingNoSignidSession"
    );
    const onBoardingNoSignToken = localStorage.getItem("onBoardingNoSignToken");
    if (data?.user.token && isOnboardingNoSign && onBoardingNoSignidSession) {
      const respEreditaSession = await api.post("/sessions/accorpare", {
        idSession: onBoardingNoSignidSession,
        fakeToken: onBoardingNoSignToken,
      });
      const respNewSession = await useSessionStore.getState().getSession(); //riprendo la nuova sessione ereditata
      // console.log(respNewSession)

      localStorage.removeItem("isOnboarding_without_sign");
      localStorage.removeItem("onBoardingNoSignidSession");
      localStorage.removeItem("onBoardingNoSignToken");
      callbackCalcolaFree?.();
      console.log("COMPLETED LOG CALCOLAFREE");
    } else {
      callback();
    }
  },
  userLogOut: async (callback) => {
    const id = localStorage.getItem("idToFetchSavedSessionTokenAndId");
    if (id) await useSessionStore.getState().deleteSavedIdAndToken(id);
    // await api.get("/logout")
    // set({ user: null, justSigned: false });
    api.removeAuthHeader();

    //solo per il flusso onboarding senza registrazione mi salvo i dati per poi ereditarli
    const isOnboardingNoSign = localStorage.getItem(
      "isOnboarding_without_sign"
    );
    const onBoardingNoSignidSession = localStorage.getItem(
      "onBoardingNoSignidSession"
    );
    const onBoardingNoSignToken = localStorage.getItem("onBoardingNoSignToken");
    const utm = localStorage.getItem("utm");
    const friendCode = localStorage.getItem("friendCodeFromFreeOnboarding");

    localStorage.clear();

    //solo per il flusso onboarding senza registrazione ri-setto i dati per poi ereditarli
    if (
      isOnboardingNoSign &&
      onBoardingNoSignidSession &&
      onBoardingNoSignToken
    ) {
      localStorage.setItem("isOnboarding_without_sign", "true");
      localStorage.setItem(
        "onBoardingNoSignidSession",
        onBoardingNoSignidSession
      );
      localStorage.setItem("onBoardingNoSignToken", onBoardingNoSignToken);
      localStorage.setItem("isOnboarding", "true");
    }

    if (utm) localStorage.setItem("utm", utm);
    if (friendCode)
      localStorage.setItem("friendCodeFromFreeOnboarding", friendCode);

    useContractsStore.getState().resetHome();
    useSessionStore.getState().resetSession();

    callback?.();
  },
  fetchUser: async (callback, callbackError) => {
    const startLoader = useStoreLoader.getState().startLoader;
    const stopLoader = useStoreLoader.getState().stopLoader;
    try {
      startLoader();
      const { data } = await api.get("/infoUser");
      set({ user: data.user });
      callback?.();
    } catch (error: Error | any) {
      if (error.response) {
        const statusCode = error.response.status;
        if (statusCode === 401 || statusCode === 404) {
          const { userLogOut } = get();
          userLogOut();
        }
      }
      callbackError?.(
        error.response?.data?.message || error.response?.data || error?.message
      );
    } finally {
      stopLoader();
    }
  },
  setUser: (user: User | null) => set({ user }),
  deleteAllDocuments: async () => {
    await api.delete("/documents/identity");
  },
  deleteDocumentFront: async () => {
    await api.delete("/documents/identity/front");
  },
  deleteDocumentBack: async () => {
    await api.delete("/documents/identity/back");
  },
  deleteDocumentPdf: async () => {
    await api.delete("/documents/identity/pdf");
  },
  uploadID: async (payload, callback) => {
    const { data } = await api.post(`/documents/${payload.sessionId}`, {
      documents: payload.documents,
    });
    callback(data.files);
  },
  deleteUser: async () => {
    const { userLogOut } = get();
    await api.delete("/user/delete");
    userLogOut();
  },
  editUser: async (payload) => {
    await api.post("/user/edit", payload);
  },
  forgotPassword: async (payload, callback) => {
    await api.post("/user/resetPwd", payload);
    callback();
  },
  resetPassword: async (payload, callback) => {
    await axios.post(
      "/user/setNewPwd",
      { otp: payload.otp, pwd: payload.pwd },
      {
        headers: {
          Authorization: payload.token,
        },
      }
    );
    callback();
  },
  updateDocumentDataManually: async (sessionId, data) => {
    await api.put(`/documents/identity/updateManualy/${sessionId}`, data);
  },
  updateSessionDataManually: async (sessionId, data, callback) => {
    await api.put(`/sessions/updataManualy/${sessionId}`, data);
    callback();
  },
  updateSessionDataManuallyBusinessInfo: async (sessionId, data, callback) => {
    await api.put(`/sessions/updateManualyBusinessInfo/${sessionId}`, data);
    callback();
  },
  updateSessionSetIban: async (sessionId, data, callback) => {
    await api.put(`/sessions/updateIban/${sessionId}`, data);
    callback();
  },
  editUserOnboarding: async (data: any, callback) => {
    try {
      const { data: response } = await api.put(
        "/user/editOnboardingUser",
        data
      );

      if (response) {
        useAuthStore.getState().fetchUser(); //ricarico l'utente
        callback();
      }
    } catch (error: Error | any) {
      console.log("error edit user onboarding");
      // const setError = useStoreError.getState().setError;
      // setError(error.response?.data?.message || error.response?.data || error?.message);
    }
  },
}));
